<template>
  <div class="task-validator-pane">
    <el-row :gutter="20" class="header">
      <el-col :span="22" class="title">
        <div class="user-display-name">
          {{ __("Task Validator") }}
        </div>
        <span class="validation-text">
          <i
            v-if="hasRunValidation && !hasErrors"
            class="el-icon-success green-icon"
          ></i>
          {{ getValidationDescription }}</span
        >
        <br />
        <span class="subtext">{{ getLastRunAtText }}</span>
      </el-col>
      <el-col :span="2" class="actions">
        <el-button
          icon="el-icon-close"
          type="text"
          @click="handleCloseValidator"
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="header">
      <el-col :span="22" class="title">
        <el-button :disabled="isLoading" type="primary" @click="validateTask"
          >{{ getButtonText }}
        </el-button>
      </el-col>
    </el-row>
    <div v-if="!showValidatorWarnings">
      <node-validation-content
        category="error"
        :is-loading="isLoading"
        :validation-content="validationError"
      />
    </div>
    <div v-else>
      <el-tabs id="task-validation-tabs" v-model="activeName">
        <el-tab-pane label="Errors" name="Errors">
          <node-validation-content
            category="error"
            :is-loading="isLoading"
            :validation-content="validationError"
          />
        </el-tab-pane>
        <el-tab-pane label="Warnings" name="Warnings">
          <node-validation-content
            category="warning"
            :is-loading="isLoading"
            :validation-content="validationWarning"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import { formatTimeStampWithTz } from "@/utils/time";
import NodeValidationContent from "@/views/build/callflow/components/task-validator/NodeValidationContent.vue";

export default {
  components: { NodeValidationContent },
  props: {
    canvasName: {
      type: String,
      default: __("Main Dialog")
    },
    taskId: {
      required: true
    }
  },
  data() {
    return {
      activeUsers: [],
      channel: null,
      showValidator: false,
      showValidatorWarnings: false,
      isSliding: false,
      isLoading: false,
      isDisabled: false,
      cancelForm: false,
      reloadEditHistory: false,
      activeName: "Errors",
      validationError: [],
      validationWarning: [],
      lastRunAt: null
    };
  },

  computed: {
    ...mapState("app", {
      timezone: state => state.timezone
    }),

    ...mapGetters("canvas", {
      getOriginalNode: "getOriginalNode"
    }),

    getValidationDescription() {
      const MSG =
        "Select Run Validation to begin the process. This can take several minutes depending on the number of nodes in your task.";
      return this.hasRunValidation
        ? this.hasErrors
          ? __("Task validation returned the following errors.")
          : __("No errors found.")
        : __(MSG);
    },

    hasRunValidation() {
      return this.lastRunAt !== null;
    },

    hasErrors() {
      return !_.isEmpty(this.validationError);
    },

    getLastRunAtText() {
      return this.hasRunValidation ? __("Last run at ") + this.lastRunAt : "";
    },

    getButtonText() {
      return this.hasRunValidation
        ? __("Rerun Validation")
        : __("Run Validation");
    }
  },

  created() {},

  async mounted() {
    this.isLoading = true;
    // check whether warnings info is to be shown
    await this.toggleShowValidatorWarnings();

    this.getTaskValidation(this.taskId)
      .then(data => {
        if (data !== undefined) {
          this.validationError = data.error;
          this.validationWarning = data.warning;
          this.lastRunAt = formatTimeStampWithTz(
            data.last_run_at,
            this.timezone,
            "MMM DD, yyyy hh:mm a"
          );
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.isLoading = false;
        this.$emit("reloaded");
      });
  },

  methods: {
    ...mapActions("canvas", {
      getNodes: "getNodes",
      toggleValidationsInProgress: "toggleValidationsInProgress"
    }),

    ...mapActions("tasks", {
      requestTaskValidation: "requestTaskValidation",
      getTaskValidation: "getTaskValidation"
    }),

    async toggleShowValidatorWarnings() {
      this.showValidatorWarnings = await this.showFeature(
        this.$getConst("SHOW_ADDITIONAL_WARNINGS_FOR_TASK_VALIDATOR")
      );
    },

    handleCloseValidator() {
      this.$emit("hideDetails");
    },

    validateTask() {
      this.isLoading = true;
      this.requestTaskValidation(this.taskId)
        .then(data => {
          this.validationError = data.error;
          this.validationWarning = data.warning;
          this.lastRunAt = formatTimeStampWithTz(
            data.last_run_at,
            this.timezone,
            "MMM DD, yyyy hh:mm a"
          );
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.isLoading = false;
          this.$emit("reloaded");
        });
    }
  },

  watch: {},

  beforeDestroy() {}
};
</script>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;

.task-validator-pane {
  .el-button--primary {
    line-height: 24px;
    background: var(--theme-color) !important;
    border-color: var(--theme-color) !important;

    &:hover {
      background: var(--theme-hover-color) !important;
      border-color: var(--theme-hover-color) !important;
      color: white;
    }
  }

  .validation-body {
    overflow-y: scroll;
    margin-bottom: 35%;

    .validation-content {
      padding: 25px 20px 15px 20px;

      .error-message {
        color: #181f29;
        font-size: 14px;
        font-weight: 500;
      }

      .clickable-node {
        color: var(--theme-color) !important;
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
      }

      .el-alert {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
        width: 90%;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
        border: 1px solid transparent;
        color: #3a3939;
      }

      .highlighted {
        border: 2px solid var(--theme-color);
      }

      .el-alert__content {
        padding-left: 0;
        max-width: 100%;

        .content-details {
          display: flex;
          align-items: center;

          .content-icon {
            height: 30px;
            outline-style: none;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding-right: 8px;
          }

          .content-body {
            max-width: 80%;

            .content-type {
              font-weight: bold;
            }

            .content-name {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .validation-text {
    color: #181f29;
    font-size: 12px;
    font-weight: 500;
    .green-icon {
      color: green;
    }
  }

  .subtext {
    font-size: 12px;
    font-style: italic;
    color: #696969;
  }
}
</style>
